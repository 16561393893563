import axios from "axios";
import * as React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const authContext = React.createContext();

export function useAuth() {
	const [authed, setAuthed] = React.useState(false);

	return {
		authed,
	};
}

export function SetUser() {
	var CJS = require("crypto-js");

	let User = sessionStorage.getItem("User");
	let LoginStamp = sessionStorage.getItem("LoginStamp");
	let Token = sessionStorage.getItem("_token");

	const a = CJS.MD5(LoginStamp.toString());
	const b = CJS.MD5(User.toString());
	const c = CJS.MD5(Token.toString());

	sessionStorage.setItem("isLoggedIn", "true");
	sessionStorage.setItem(
		"LoginHash",
		a.toString() + ":" + b.toString() + ":" + c.toString()
	);
}

export function ClearUser() {
	sessionStorage.removeItem("User");
	sessionStorage.removeItem("isLoggedIn");
	sessionStorage.removeItem("LoginStamp");
	sessionStorage.removeItem("LoginHash");
	sessionStorage.removeItem("_token");
}

export function CheckAuth() {
	let user = sessionStorage.getItem("User");
	let isLoggedIn = sessionStorage.getItem("isLoggedIn");
	let LoginStamp = sessionStorage.getItem("LoginStamp");
	let LoginHash = sessionStorage.getItem("LoginHash");
	let token = sessionStorage.getItem("_token");
	var CJS = require("crypto-js");
	if (
		user &&
		isLoggedIn &&
		LoginStamp &&
		token &&
		LoginHash &&
		isLoggedIn.toString() === "true"
	) {
		console.log(
			"Checking hash...",
			user,
			CJS.MD5(LoginStamp.toString()).toString() +
				":" +
				CJS.MD5(user.toString()).toString() +
				":" +
				CJS.MD5(token.toString()).toString(),
			LoginHash
		);
		if (
			CJS.MD5(LoginStamp.toString()).toString() +
				":" +
				CJS.MD5(user.toString()).toString() +
				":" +
				CJS.MD5(token.toString()).toString() ===
			LoginHash
		) {
			return true;
		}
		// hash failed clear it out
		ClearUser();
	}
	return false;
}

export function RequireAuth({ children }) {
	const location = useLocation();

	if (CheckAuth()) {
		return children;
	}
	return <Navigate to="/login" replace state={{ path: location.pathname }} />;
}

export function AuthProvider({ children }) {
	const auth = useAuth();

	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
	return React.useContext(authContext);
}
