import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import "./Login.css";

let SingleSignOnClick = (ev) => {
	window.location.href = "https://bliss.baldwinandco.co.uk/";

	/*const Dispatch = useDispatch();
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    var UUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
    let ref = () => {Dispatch(RecordRef(UUID));}
    
    let ref2 = () => {Dispatch(LoggingIn());}

   return () => {
       ref();
       window.open(process.env.REACT_APP_SSO_URL + '?_ref=' + UUID, '_blank');
       ref2();
       alert("Done");
   };*/
};

const LoginPage = () => {
	return (
		<section className="vh-100">
			<div className="container py-5 h-100">
				<div className="row align-items-center h-20">
					<img
						src="/assets/img/BlissLogoAzets1.svg"
						className="img-fluid m-5 BLISSLogo"
						alt="BLISS Logo"
					/>
				</div>
				<div className="row d-flex align-items-center justify-content-center h-80">
					<div className="col-md-8 col-lg-7 col-xl-6">
						<img
							src="/assets/img/AzetsLoginScreenImage.svg"
							className="img-fluid"
							alt="Azets Login"
						/>
					</div>

					<div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 p-5 pt-0 rounded-3 shadow-lg card bg-dark text-white ">
						<div className="divider d-flex align-items-center my-4">
							<p className="text-center fw-bold mx-3 mb-0 h3">Client Login</p>
						</div>
						<form>
							<div className="form-outline mb-4">
								<input
									type="email"
									id="form1Example13"
									className="form-control form-control-lg"
									placeholder="Email Address"
								/>
							</div>

							<div className="form-outline mb-4">
								<input
									type="password"
									id="form1Example23"
									className="form-control form-control-lg"
									placeholder="Password"
								/>
							</div>

							<div className="text-end justify-content-center">
								<a href="#!" className="m-5">
									Forgot password?
								</a>
								<button
									type="submit"
									className="btn btn-primary btn-lg btn-block"
								>
									Sign in
								</button>
							</div>

							<div className="divider d-flex align-items-center my-4">
								<p className="text-center fw-bold mx-3 mb-0 h3">Staff Login</p>
							</div>
							<div className="d-grid gap-2">
								<button
									type="button"
									className="btn btn-primary btn-lg"
									onClick={SingleSignOnClick}
								>
									Single Sign On
								</button>
							</div>
							<p>{process.env.REACT_APP_SSO_URL}</p>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LoginPage;
