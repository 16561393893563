import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { RequireAuth, CheckAuth } from "./Hooks/Auth";
import HeaderBar from "./Layout/HeaderBar";
import MainArea from "./Layout/MainArea";
import LoadingScreen from "./Layout/LoadingScreen";
import LoginPage from "./Pages/Auth/Login";
import DashboardPage from "./Pages/Dashboard";
import ForceExternalRedirect from "./Components/ForceExternalRedirect";

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(CheckAuth());

	return (
		<>
			<BrowserRouter>
				<Routes>
					{/* Temporary route for F and P */}
					{/* https://bliss.azets.co.uk/en/Compliance/FitAndProperDeclaration */}
					<Route
						exact
						path="/en/Compliance/FitAndProperDeclaration"
						element={<ForceExternalRedirect url="https://bliss.baldwinandco.co.uk/en/Compliance/FitAndProperDeclaration" />
						}
					/>
					{/* Temporary route for F and P */}
					<Route exact path="/login" element={<LoginPage />} />
					<Route
						exact
						path="/"
						element={
							<Suspense fallback={<LoadingScreen />}>
								<RequireAuth>
									<HeaderBar />
									<MainArea>
										<DashboardPage />
									</MainArea>
								</RequireAuth>
							</Suspense>
						}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
