import React, { useEffect } from "react";

let ForceExternalRedirect = (props) => {
	useEffect(() => {
		window.location.href = props.url;
	}, []);

	return <div></div>;
};

export default ForceExternalRedirect;
