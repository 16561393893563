let MainArea = ({ Children }) => {
	return (
		<>
			<p>Navigation Here</p>
			{Children}
		</>
	);
};

export default MainArea;
